

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, IForm,
} from '@/modules/scheduled-reports/interfaces';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import DILiteService, { DiLiteAllChannelsServiceS } from '../di-lite-all-channels.service';
import { DILiteFilterDevice, DILiteFilterDeviceLabels } from '../models/all-channels-settings.model';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(DiLiteAllChannelsServiceS) diliteService!: DILiteService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly dataType = DATA_TYPE.DI_LITE;

    private form: IForm = {} as IForm;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60, 90],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    private defineCompsetFilter(filters: IFilterItem[]) {
        const { compsets, currentCompset } = this.compsetsService;
        const defaultValue = currentCompset?.id || '';
        const value = String(this.form.filters?.compset || defaultValue);
        const options = (compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));
        filters.push({
            name: 'compset',
            label: this.$tc('titles.compset'),
            value,
            options,
            disableOnEdit: true,
        });
    }

    private definePosFilter(filters: IFilterItem[]) {
        const { pos } = this.diliteService.filters;
        const { posRatesItems: options } = this.documentFiltersService;

        const defaultValue = pos || options?.[0]?.value || '';
        const value = String(this.form.filters?.pos || defaultValue);

        filters.push({
            name: 'pos',
            label: this.$tc('titles.pos'),
            value,
            options,
            disableOnEdit: false,
        });
    }

    private defineLosFilter(filters: IFilterItem[]) {
        const { filters: { los } } = this.diliteService;
        const { compset } = this.diliteService;

        const losList = compset
            ? compset.los
            : [1];

        const options = losList.map(los => ({
            name: this.$t('filters.los.num', [los]).toString(),
            value: los,
        }));

        const defaultValue = los || options?.[0]?.value || DEFAULT_LOS;
        const value = Number(this.form.filters?.los || defaultValue);

        filters.push({
            name: 'los',
            label: this.$tc('titles.los'),
            value,
            options,
            disableOnEdit: false,
        });
    }

    private defineMealTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const { mealTypeId } = this.diliteService.settings;
        const { mealTypes } = this.mealTypesService;

        const defaultMealTypeValues = mealTypeId === ANY_MEAL_TYPE.id
            ? mealTypes
                .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                .map(mt => this.$tc(mt.name))
            : [this.mealTypesService.getMealType(mealTypeId)!.name];

        const choosenMealTypes = (values.meal_type || defaultMealTypeValues) as string[];

        filters.push({
            name: 'meal_type',
            label: this.$tc('titles.mealType'),
            value: choosenMealTypes,
            options: mealTypes
                .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                .map((mealType: MealTypeModel) => ({
                    value: mealType.name,
                    name: this.$tc(mealType.displayName),
                })),
            disableOnEdit: false,
        });
    }

    private defineRoomTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { rooms } = this.roomTypesService;

        const { roomTypeId } = this.diliteService.settings;
        const defaultRoomTypeValues = roomTypeId === ANY_ROOM_TYPE.id
            ? rooms
                .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                .map(rt => rt.name)
            : [this.roomTypesService.getRoomType(roomTypeId)!.name];

        const choosenRoomTypes = (values.room_type || defaultRoomTypeValues) as number[];

        filters.push({
            name: 'room_type',
            label: this.$tc('titles.roomType'),
            value: choosenRoomTypes,
            options: rooms
                .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                .map((room: RoomTypeModel) => ({
                    value: room.name,
                    name: this.$tc(room.name),
                })),
            disableOnEdit: false,
        });
    }

    private defineOccupancyFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenOccupancy = values.occupancy
            || this.diliteService.settings.numberOfGuests
            || this.userSettingsService.defaultFilters.numberOfGuests;

        filters.push({
            name: 'occupancy',
            label: this.$tc('titles.occupancy'),
            value: choosenOccupancy as number,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: this.$tc('filters.guests', e, [e]).toString(),
                })),
            disableOnEdit: false,
        });
    }

    private definePriceFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        const choosenPriceType = values.price
            || this.diliteService.settings.priceType
            || PRICE_TYPE.LOWEST;

        filters.push({
            name: 'price',
            label: this.$tc('titles.price'),
            value: choosenPriceType as PRICE_TYPE,
            options: [
                {
                    name: this.$tc('price.lowest'),
                    value: PRICE_TYPE.LOWEST,
                },
                {
                    name: this.$tc('price.lowest_flex'),
                    value: PRICE_TYPE.LOWEST_FLEX,
                },
                {
                    name: this.$tc('price.best_flex'),
                    value: PRICE_TYPE.BEST_FLEX,
                },
                {
                    name: this.$tc('price.non_refundable'),
                    value: PRICE_TYPE.NON_REFUNDABLE,
                },
            ],
            disableOnEdit: false,
        });
    }

    private definePriceTypeFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;

        let priceTypeValue = values.price_type as string;

        if (!priceTypeValue) {
            switch (this.documentFiltersService.priceShown) {
                case PRICE_SHOWN.TOTAL:
                    priceTypeValue = 'total_price';
                    break;
                case PRICE_SHOWN.NET:
                    priceTypeValue = 'net_price';
                    break;
                default:
                    priceTypeValue = 'shown_price';
                    break;
            }
        }

        filters.push({
            name: 'price_type',
            label: this.$tc('titles.priceType'),
            value: priceTypeValue,
            options: [
                { value: 'shown_price', name: this.$tc('filters.price.shown') },
                { value: 'net_price', name: this.$tc('filters.price.net') },
                { value: 'total_price', name: this.$tc('filters.price.total') },
            ],
            disableOnEdit: false,
        });
    }

    private defineDeviceFilter(filters: IFilterItem[]) {
        const defaultValue = this.diliteService.settings.device || DILiteFilterDevice.ALL;
        const value = String(this.form.filters?.device_name || defaultValue);

        filters.push({
            name: 'device_name',
            label: this.$tc('titles.deviceName'),
            value,
            options: Object
                .entries(DILiteFilterDeviceLabels)
                .map(([key, value]) => ({
                    name: this.$tc(value),
                    value: key,
                })),
            disableOnEdit: false,
        });
    }
    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        this.defineCompsetFilter(filters);
        this.definePosFilter(filters);
        this.defineLosFilter(filters);
        this.defineMealTypeFilter(filters);
        this.defineRoomTypeFilter(filters);
        this.defineOccupancyFilter(filters);
        this.definePriceTypeFilter(filters);
        this.definePriceFilter(filters);
        this.defineDeviceFilter(filters);

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '6',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
